import React from "react";
import Navbar from "./components/Navbar"
import Main from "./components/Main"

export default function App() {
  return (
    <div className = "bg-forest">
        <Navbar/>
        <Main/>
    </div>
  );
}
